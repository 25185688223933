/**
 * 模板设置（二维码链接）
 * 字符串前添加 `[url]` 表示自动生成二维码
 * 字符串前添加 `[img]` 表示使用后面的链接作为二维码图片的地址（支持 base64）
 * `url` 或 `img` 前加英文感叹号 `!` 表示不使用像素化缩放二维码图片
 * 若不添加则默认为 `[url]`
 */
module.exports = {
  Alipay: "https://qr.alipay.com/fkx114944v2ccnidkwpk687",
  // 微信赞赏码，也可以使用微信支付二维码扫描后以 `wxp://` 开头的网址，但是不推荐使用微信支付二维码，以免被他人用于非法用途
  WeChat: "wxp://f2f0QkSy6E0TGOxOWHvVYcwnBLr2rdJfH8CVp74MbhGvFTA",
  QQ: "https://i.qianbao.qq.com/wallet/sqrcode.htm?m=tenpay&f=wallet&a=1&u=3340515767&n=%E6%9A%AE%E8%89%B2%E3%80%81%E6%B8%85%E9%A2%A8&ac=CAEQt_PwuAwYzdrTtwZCIDc0MjhkNjEzNWFiODhjZTk0ZmVlZjQyODg3ODUxNThk_xxx_sign"
}