"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handlePageConfig = exports.mergeOptions = void 0;
const declaration_1 = require("./declaration");
const mergeOptions = (options, patch) => {
    for (const key of Array.from(new Set([...Object.keys(options), ...Object.keys(patch)]))) {
        const _options = options[key], _patch = patch[key];
        if (isJSON(_options) && isJSON(_patch))
            patch[key] = (0, exports.mergeOptions)(_options, _patch);
    }
    return { ...options, ...patch };
};
exports.mergeOptions = mergeOptions;
function isJSON(obj) {
    return typeof obj == 'object' && obj != null && !Array.isArray(obj) && JSON.stringify(obj).indexOf('{') == 0;
}
const handlePageConfig = (params) => {
    return (0, exports.mergeOptions)(pickOutPlatformSpecificForObject(declaration_1.DEFAULT_PAGE_CONFIG), pickOutPlatformSpecificForObject(params));
};
exports.handlePageConfig = handlePageConfig;
function pickOutPlatformSpecific(value) {
    if (isJSON(value) && Object.keys(value).includes("pc") && Object.keys(value).includes("mobile"))
        return value[declaration_1.IS_MOBILE ? "mobile" : "pc"];
    else
        return value;
}
function pickOutPlatformSpecificForObject(obj) {
    for (const key in obj) {
        obj[key] = pickOutPlatformSpecific(obj[key]);
    }
    return obj;
}
