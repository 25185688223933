"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redirect = void 0;
const __1 = require("..");
const config_1 = require("../config");
const box_util_1 = require("./box-util");
const declaration_1 = require("./declaration");
function jumpToURL(url) {
    if (__1.FINAL_CONFIG.iframe_no_redirect && declaration_1.IS_IFRAME) {
        declaration_1.Message.post({
            type: "openurl",
            value: url
        });
    }
    else
        window.location.href = url;
}
var DOMContentLoaded = false;
document.addEventListener("DOMContentLoaded", function () {
    DOMContentLoaded = true;
});
function redirect(type, value) {
    if (typeof value == 'undefined')
        jumpToURL(type);
    else if (type == 'url')
        jumpToURL(value);
    else if (type == 'qrcode')
        window.location.href = config_1.QRCodeContent[value];
    else if (type == 'at-platform') {
        const t = config_1.AtPlatformEvent[value];
        typeof t == "string" ?
            jumpToURL(t) : (DOMContentLoaded ?
            t(value) :
            document.addEventListener("DOMContentLoaded", () => t(value)));
    }
    else if (type == 'pc-select') {
        const t = config_1.PCSelectClickEvent[value];
        typeof t == "string" ?
            (box_util_1.Box.QRCode.set(t), box_util_1.Box.QRCode.changeAlt(value)) : (DOMContentLoaded ?
            t(value) :
            document.addEventListener("DOMContentLoaded", () => t(value)));
    }
    else if (type == 'mobile-select') {
        const t = config_1.MobileSelectClickEvent[value];
        typeof t == "string" ?
            jumpToURL(t) : (DOMContentLoaded ?
            t(value) :
            document.addEventListener("DOMContentLoaded", () => t(value)));
    }
    else
        throw new Error(`Unkown type "${type}"`);
}
exports.redirect = redirect;
