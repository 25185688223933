"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Style = exports.SetStyleVariables = exports.GetStyleVariable = void 0;
const COMPUTED_STYLE = window.getComputedStyle(document.documentElement);
const GetStyleVariable = (key) => {
    return COMPUTED_STYLE.getPropertyValue("--" + key);
};
exports.GetStyleVariable = GetStyleVariable;
const SetStyleVariables = (key, value) => {
    return document.documentElement.style.setProperty("--" + key, value);
};
exports.SetStyleVariables = SetStyleVariables;
var Style;
(function (Style) {
    var DEFAULT_OPTIONS;
    Style.registerDefaultOpt = (options) => {
        DEFAULT_OPTIONS = options;
    };
    /**
     * 设置单个变量
     */
    Style.modify = (key, value) => (0, exports.SetStyleVariables)(key, value);
    /**
     * 设置多个变量
     */
    Style.set = (params) => {
        for (const key in params) {
            Style.modify(key, params[key]);
        }
    };
    Style.setDefault = () => Style.set(DEFAULT_OPTIONS);
})(Style = exports.Style || (exports.Style = {}));
