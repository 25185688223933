"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FINAL_CONFIG = void 0;
require("../stylesheets/index.scss");
const declaration_1 = require("./components/declaration");
const box_util_1 = require("./components/box-util");
const render_1 = require("./components/render");
const redirect_1 = require("./components/redirect");
const config_1 = require("./config");
const page_config_1 = require("./components/page-config");
const url_params_1 = require("./components/url-params");
const style_util_1 = require("./components/style-util");
const PAGE_CONFIG = (0, page_config_1.handlePageConfig)(config_1.DefaultPageConfig);
const URL_PARAMS = (0, url_params_1.GetUrlParams)();
const PLATFORM = URL_PARAMS.platform || declaration_1.CUR_PLATFORM;
exports.FINAL_CONFIG = {
    ...PAGE_CONFIG,
    auto: URL_PARAMS.auto == undefined ? PAGE_CONFIG.auto : URL_PARAMS.auto,
    type: URL_PARAMS.type == undefined ? PAGE_CONFIG.type : URL_PARAMS.type
};
// Render the HTML Element
document.addEventListener("DOMContentLoaded", function () {
    (0, render_1.renderBox)(Boolean(exports.FINAL_CONFIG.auto || URL_PARAMS.platform));
});
var setBoxOptions = {
    qrcode: declaration_1.IS_MOBILE ? config_1.DefaultLiteral.QRCodeContent_Mobile : config_1.DefaultLiteral.QRCodeContent_PC,
    qrcode_alt: config_1.DefaultLiteral.QRCodeAlt,
    qrcode_pixelated: true,
    title: {
        "icon": {
            pc: config_1.DefaultLiteral.Title_PC,
            mobile: config_1.DefaultLiteral.Title_Mobile
        },
        "logo": {
            pc: config_1.DefaultLiteral.Title_PC,
            mobile: config_1.DefaultLiteral.Title_Mobile
        },
        "select": {
            pc: config_1.DefaultLiteral.TitleSelect_PC,
            mobile: config_1.DefaultLiteral.TitleSelect_Mobile
        }
    }[exports.FINAL_CONFIG.type][declaration_1.IS_MOBILE ? "mobile" : "pc"],
    text: {
        "icon": {
            pc: config_1.DefaultLiteral.Text_PC,
            mobile: config_1.DefaultLiteral.Text_Mobile
        },
        "logo": {
            pc: config_1.DefaultLiteral.Text_PC,
            mobile: config_1.DefaultLiteral.Text_Mobile
        },
        "select": {
            pc: config_1.DefaultLiteral.TextSelect_PC,
            mobile: config_1.DefaultLiteral.TextSelect_Mobile
        }
    }[exports.FINAL_CONFIG.type][declaration_1.IS_MOBILE ? "mobile" : "pc"],
    icon: exports.FINAL_CONFIG.type == "icon" ? declaration_1.SUPPORTED_PLATFORMS : [],
    logo: exports.FINAL_CONFIG.type == "logo" ? declaration_1.SUPPORTED_PLATFORMS : [],
    select: exports.FINAL_CONFIG.type == "select" ? declaration_1.SUPPORTED_PLATFORMS : [],
};
box_util_1.Box.registerDefaultOpt(setBoxOptions);
var setStyleOptions = {
    "theme-color-1": (0, style_util_1.GetStyleVariable)("theme-color-1"),
    "theme-color-2": (0, style_util_1.GetStyleVariable)("theme-color-2"),
    "font-color-1": (0, style_util_1.GetStyleVariable)("font-color-1"),
    "font-color-2": (0, style_util_1.GetStyleVariable)("font-color-2")
};
style_util_1.Style.registerDefaultOpt(setStyleOptions);
// Automatically redirect to platform page
if (exports.FINAL_CONFIG.auto || URL_PARAMS.platform) {
    if (typeof PLATFORM == "string")
        (0, redirect_1.redirect)('at-platform', PLATFORM);
}
// Render Box
document.addEventListener("DOMContentLoaded", function () {
    box_util_1.Box.setDefault(false, true);
});
// Post Container Height
document.addEventListener("DOMContentLoaded", function () {
    const DOM = document.getElementsByClassName("container")[0];
    const postContainerSize = () => {
        if (declaration_1.IS_IFRAME) {
            const { height, width } = window.getComputedStyle(DOM);
            declaration_1.Message.post({
                type: "size",
                value: { height, width }
            });
        }
    };
    // Register the observer
    const observerConfig = {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
    };
    var observer = new MutationObserver((rec, obs) => {
        postContainerSize();
        obs.disconnect();
        setTimeout(() => { obs.observe(DOM, observerConfig); }, 1);
    });
    observer.observe(DOM, observerConfig);
    // Register the message receiver
    declaration_1.Message.receive((data) => {
        if (data.type = "query-size") {
            postContainerSize();
        }
    });
    // Repost the size
    postContainerSize();
});
// Correct the conditoiin that in Mobile Edge clientHeight is not `equal` to `innerHeight`
function justifyInnerHeight() {
    (0, style_util_1.SetStyleVariables)("inner-height", window.innerHeight + "px");
}
document.addEventListener("DOMContentLoaded", justifyInnerHeight);
window.addEventListener("resize", justifyInnerHeight);
