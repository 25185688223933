"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetUrlParams = exports.GetUrlValue = void 0;
const declaration_1 = require("./declaration");
const GetOriginalUrlValue = function (name) {
    var reg = new RegExp('(^|&)' + encodeURI(name) + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.slice(1).match(reg);
    if (r != null) {
        try {
            let val = decodeURIComponent(r[2]);
            return /^[0-9]+$/.test(val) ? Number(val) : val;
        }
        catch (e) {
            return undefined;
        }
    }
    return undefined;
};
function isSupportedPlatform(platform) {
    if (typeof platform !== "string")
        return false;
    return declaration_1.SUPPORTED_PLATFORMS.includes(platform);
}
function GetUrlValue(name) {
    if (name == "auto") {
        const val = GetOriginalUrlValue("auto");
        return typeof val == "undefined" ? undefined : Boolean(val);
    }
    else if (name == "platform") {
        const val = GetOriginalUrlValue("platform");
        return isSupportedPlatform(val) ? val : undefined;
    }
    else if (name == "type") {
        const val = GetOriginalUrlValue("type");
        return (typeof val == "string" && ["icon", "logo", "select"].includes(val)) ? val : undefined;
    }
}
exports.GetUrlValue = GetUrlValue;
const GetUrlParams = () => {
    return {
        auto: GetUrlValue("auto"),
        platform: GetUrlValue("platform"),
        type: GetUrlValue("type")
    };
};
exports.GetUrlParams = GetUrlParams;
