"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Message = exports.registerMessageHandler = exports.postParentMessage = exports.isIframe = void 0;
const declaration_1 = require("./declaration");
function isIframe() { return window.self != window.top; }
exports.isIframe = isIframe;
const IDENTIFIER_PREFIX = "[universal-payment]+";
const postParentMessage = (message, onlyIframe = true) => {
    if (onlyIframe && !declaration_1.IS_IFRAME)
        return;
    const origin = (function () {
        try {
            return window.parent.location.origin;
        }
        catch {
            return document.referrer || "*";
        }
    })();
    window.parent.postMessage(IDENTIFIER_PREFIX + JSON.stringify(message), origin);
};
exports.postParentMessage = postParentMessage;
const registerMessageHandler = (handler, onlyIframe = true) => {
    if (onlyIframe && !declaration_1.IS_IFRAME)
        return;
    window.self.addEventListener("message", function (e) {
        if (typeof e.data == "string" && e.data.indexOf(IDENTIFIER_PREFIX) == 0) {
            try {
                const data = JSON.parse(e.data.slice(IDENTIFIER_PREFIX.length));
                if (typeof data["type"] == "string")
                    handler.call(this, data);
            }
            catch (_) { }
        }
    });
};
exports.registerMessageHandler = registerMessageHandler;
var Message;
(function (Message) {
    Message.post = exports.postParentMessage;
    Message.receive = exports.registerMessageHandler;
})(Message = exports.Message || (exports.Message = {}));
