"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_PAGE_CONFIG = exports.IS_IFRAME = exports.IS_MOBILE = exports.BASE_URL = exports.CUR_PLATFORM = exports.SUPPORTED_PLATFORMS = exports.Message = void 0;
const message_1 = require("./message");
Object.defineProperty(exports, "Message", { enumerable: true, get: function () { return message_1.Message; } });
const user_agent_1 = require("./user-agent");
exports.SUPPORTED_PLATFORMS = (0, user_agent_1.getSupportPlaforms)();
exports.CUR_PLATFORM = (0, user_agent_1.getPlatform)();
exports.BASE_URL = window.location.origin + window.location.pathname;
exports.IS_MOBILE = (0, user_agent_1.isMobile)();
exports.IS_IFRAME = (0, message_1.isIframe)();
Object.defineProperty(this, "IS_MOBILE", { get: user_agent_1.isMobile });
Object.defineProperty(this, "IS_IFRAME", { get: message_1.isIframe });
exports.DEFAULT_PAGE_CONFIG = {
    auto: true,
    type: {
        pc: "icon",
        mobile: "select"
    },
    iframe_no_redirect: true
};
// Override `alert`
const _alert = window.alert;
window.alert = (message) => setTimeout(() => _alert(message), 1);
