"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderBox = void 0;
const declaration_1 = require("./declaration");
const box_util_1 = require("./box-util");
const redirect_1 = require("./redirect");
const style_util_1 = require("./style-util");
const config_1 = require("../config");
function node(type, platform) {
    const node = document.createElement("div");
    node.classList.add(type);
    node.classList.add(`icon-${platform}`);
    node.setAttribute("data-platform", platform);
    return node;
}
function textNode() {
    return document.createTextNode("\n");
}
const renderBox = (isAtPlatform = false) => {
    for (const platform of declaration_1.SUPPORTED_PLATFORMS) {
        box_util_1.Box.Icon.DOM.appendChild(node("icon", platform));
        box_util_1.Box.Icon.DOM.appendChild(textNode());
    }
    for (const platform of declaration_1.SUPPORTED_PLATFORMS) {
        box_util_1.Box.Logo.DOM.appendChild(node("logo", platform));
        box_util_1.Box.Logo.DOM.appendChild(textNode());
    }
    let pay_name_max_length = 0;
    for (const platform of declaration_1.SUPPORTED_PLATFORMS) {
        var s_node = node("select", platform);
        box_util_1.Box.Select.DOM.appendChild(s_node);
        box_util_1.Box.Select.DOM.appendChild(textNode());
        // ClickEvent
        s_node.addEventListener("click", function () {
            if (this.classList.contains("active")) {
                // click to vanish
                this.classList.remove("active");
                isAtPlatform && declaration_1.CUR_PLATFORM ? // 支持自动跳转且位于自动跳转的界面
                    (0, redirect_1.redirect)("at-platform", platform) :
                    style_util_1.Style.setDefault(), box_util_1.Box.setDefault(false, false);
            }
            else {
                // click to activate
                for (const e of this.parentElement.children)
                    e.classList.remove("active");
                this.classList.add("active");
                style_util_1.Style.setDefault();
                declaration_1.IS_MOBILE && typeof config_1.MobileSelectClickEvent[platform] == "string" ? // 手机端且为链接跳转
                    box_util_1.Box.setDefault(false, false) :
                    box_util_1.Box.setDefaultWithoutQRCode(false, false);
                (0, redirect_1.redirect)(declaration_1.IS_MOBILE ? "mobile-select" : "pc-select", platform);
            }
        });
        // Name Length
        let name_length = window.getComputedStyle(s_node, "::after").content.length - 2;
        pay_name_max_length = name_length > pay_name_max_length ? name_length : pay_name_max_length;
    }
    (0, style_util_1.SetStyleVariables)("pay-name-max-width", pay_name_max_length + "em");
};
exports.renderBox = renderBox;
