"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMobile = exports.getSupportPlaforms = exports.getPlatform = void 0;
const config_1 = require("../config");
function getPlatform() {
    for (const platform in config_1.UserAgentRegExp)
        if (config_1.UserAgentRegExp[platform].test(navigator.userAgent))
            return platform;
    return undefined;
}
exports.getPlatform = getPlatform;
function getSupportPlaforms() {
    return Object.keys(config_1.UserAgentRegExp);
}
exports.getSupportPlaforms = getSupportPlaforms;
function isMobile() {
    return /mobile|android|iphone/i.test(navigator.userAgent);
}
exports.isMobile = isMobile;
